import React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';

import { Section } from '../components/containers';

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title='404: Not found' />

      <Section>
        <h1>This is awkward</h1>
        <h2>We can't find the page you're after.</h2>
        <h3>
          <a href='/'>Get back on track</a>
        </h3>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
